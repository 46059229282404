export default {
  list: {
    table: {
      header: {
        rowNumber: 'Row',
        user: 'User',
        description: 'Description',
        date: 'Date'
      }
    },

    dialogs: {
      title: 'Descriptions',

      noChange: 'No field change'
    }
  },

  login: 'User login',
  reset_app: 'Reset app',
  switch_active_financial_year: 'Switch active financial year',

  user: {
    store: 'User info created',
    update: 'User info updated',
    soft_destroy: 'User info temporary deleted',
    restore: 'User info restored',
    destroy: 'User info destroyed',
    set_role: 'Set role to user',
    set_max_debt: 'Set max debt to user'
  },

  user_address: {
    store: 'User address created',
    update: 'User address updated',
    destroy: 'User address deleted'
  },

  invoice: {
    store: 'Invoice created',
    update: 'Invoice info updated',
    full_update: 'Invoice info force updated',
    soft_destroy: 'Invoice info temporary deleted',
    restore: 'Invoice info restored',
    destroy: 'Invoice info deleted',
    complete: 'Invoice payed',
    refresh_status: 'Invoice refresh status'
  },

  sale_invoice: {
    store: 'Sale invoice created',
    update: 'Sale invoice info updated',
    full_update: 'Sale invoice info force updated',
    soft_destroy: 'Sale invoice info temporary deleted',
    restore: 'Sale invoice info restored',
    destroy: 'Sale invoice info deleted',
    complete: 'Sale invoice payed',
    refresh_status: 'Sale invoice refresh status'
  },

  return_sale_invoice: {
    store: 'Sale refund invoice created',
    update: 'Sale refund invoice info updated',
    full_update: 'Sale refund invoice info force updated',
    soft_destroy: 'Sale refund invoice info temporary deleted',
    restore: 'Sale refund invoice info restored',
    destroy: 'Sale refund invoice info deleted',
    complete: 'Sale refund invoice payed',
    refresh_status: 'Sale refund invoice refresh status'
  },

  purchase_invoice: {
    store: 'Purchase invoice created',
    update: 'Purchase invoice info updated',
    full_update: 'Purchase invoice info force updated',
    soft_destroy: 'Purchase invoice info temporary deleted',
    restore: 'Purchase invoice info restored',
    destroy: 'Purchase invoice info deleted',
    complete: 'Purchase invoice payed',
    refresh_status: 'Purchase invoice refresh status'
  },

  return_purchase_invoice: {
    store: 'Purchase refund invoice created',
    update: 'Purchase refund invoice info updated',
    full_update: 'Purchase refund invoice info force updated',
    soft_destroy: 'Purchase refund invoice info temporary deleted',
    restore: 'Purchase refund invoice info restored',
    destroy: 'Purchase refund invoice info deleted',
    complete: 'Purchase refund invoice payed',
    refresh_status: 'Purchase refund invoice refresh status'
  },

  product: {
    store: 'Product info created',
    update: 'Product info updated',
    soft_destroy: 'Product temporary deleted',
    restore: 'Product info restored',
    destroy: 'Product info deleted',
    pricing: 'Product pricing done'
  },

  role: {
    store: 'Role created',
    update: 'Role info updated',
    destroy: 'Role info deleted'
  },

  receive: {
    store: 'Receive document created',
    update: 'Receive document updated',
    soft_destroy: 'Receive document temporary deleted',
    restore: 'Receive document restored',
    destroy: 'Receive document deleted'
  },

  payment: {
    store: 'Payment document created',
    update: 'Payment document updated',
    soft_destroy: 'Payment document temporary deleted',
    restore: 'Payment document restored',
    destroy: 'Payment document deleted'
  },

  internal_fund: {
    store: 'Internal fund document created',
    update: 'Internal fund document updated',
    soft_destroy: 'Internal fund document temporary deleted',
    restore: 'Internal fund document restored',
    destroy: 'Internal fund document deleted'
  },

  cost: {
    store: 'Cost document created',
    update: 'Cost document updated',
    soft_destroy: 'Cost document temporary deleted',
    restore: 'Cost document restored',
    destroy: 'Cost document deleted'
  },

  tax: {
    store: 'Tax document created',
    update: 'Tax document updated',
    soft_destroy: 'Tax document temporary deleted',
    restore: 'Tax document restored',
    destroy: 'Tax document deleted'
  },

  bank: {
    store: 'Bank info created',
    update: 'Bank info updated',
    soft_destroy: 'Bank info temporary deleted',
    restore: 'Bank info restored',
    destroy: 'Bank info deleted'
  },

  cash: {
    store: 'Cash info created',
    update: 'Cash info updated',
    soft_destroy: 'Cash info temporary deleted',
    restore: 'Cash info restored',
    destroy: 'Cash info deleted'
  },

  payment_gateway: {
    store: 'Payment gateway info created',
    update: 'Payment gateway info updated',
    soft_destroy: 'Payment gateway info temporary deleted',
    restore: 'Payment gateway info restored',
    destroy: 'Payment gateway info deleted'
  },

  service: {
    store: 'Service info created',
    update: 'Service info updated',
    soft_destroy: 'Service info temporary deleted',
    restore: 'Service info restored',
    destroy: 'Service info deleted'
  },

  storeroom_receipt: {
    store: 'Storeroom receipt info created',
    accept: 'Storeroom receipt info confirmed',
    update: 'Storeroom receipt info updated'
  },

  storeroom: {
    store: 'Storeroom info created',
    update: 'Storeroom info updated',
    soft_destroy: 'Storeroom info temporary deleted',
    restore: 'Storeroom info restored',
    destroy: 'Storeroom info deleted'
  },

  storeroom_deficit: {
    store: 'Warehouse deficit info created',
    update: 'Warehouse deficit info updated',
    soft_destroy: 'Warehouse deficit info temporary deleted',
    restore: 'Warehouse deficit info restored',
    destroy: 'Warehouse deficit info deleted'
  },

  storeroom_conversion: {
    store: 'Warehouse conversion info created',
    update: 'Warehouse conversion info updated',
    soft_destroy: 'Warehouse conversion info temporary deleted',
    restore: 'Warehouse conversion info restored',
    destroy: 'Warehouse conversion info deleted'
  },

  variablesLabel: {
    invoice: {
      type: {
        1: 'Sale',
        2: 'Purchase',
        3: 'Sale refund',
        4: 'Purchase refund'
      },
      action_type: {
        1: 'Retail',
        2: 'Telephone',
        3: 'Internet'
      },
      status: {
        1: 'Draft',
        2: 'Sales invoice',
        3: 'Exit / Receive Warehouse',
        4: 'incomplete'
      }
    },
    product: {
      type: {
        1: 'simple',
        2: 'variable'
      },

      status: {
        0: 'no connection',
        1: 'draft',
        2: 'pending',
        3: 'published',
        4: 'private'
      }
    },
    receive: {
      status: {
        0: 'pending',
        1: 'confirm'
      }
    },
    storeroom_deficit: {
      status: {
        0: 'Draft',
        1: 'Approved'
      }
    },
    storeroom_conversion: {
      status: {
        0: 'Draft',
        1: 'Approved'
      }
    },
    tax: {
      type: {
        1: 'Corporate',
        2: 'Vat'
      }
    },
    types: {
      store: 'Store',
      update: 'Update',
      destroy: 'Destroy',
      soft_destroy: 'Soft destroy',
      restore: 'Restore',
      complete: 'Complete sale invoice',
      accept: 'Accept Delivery note',
      pricing: 'Pricing',
      login: 'Login',
      full_update: 'Force update',
      reset_app: 'Reset app'
    }
  },

  changeFields: {
    store: {
      user: {
        name: 'Name set to "{new}".',
        family: 'Family set to "{new}".',
        gender: 'Gender set to "{new}".',
        avatar: 'User Avatar stored',
        company: 'Company set to "{new}".',
        phone_number: 'Phone number set to "{new}".',
        email: 'Email set to "{new}".',
        max_debt: 'Max debt set to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address set to "{new}".',
          city: 'City set to "{new}".',
          province: 'Province set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          title: 'Title set to "{new}".'
        }
      },

      product: {
        type: 'Product type set to "{new}"',
        title: 'Ecommerce name set to "{new}"',
        status: 'Product status set to "{new}"',
        slug: 'Slug set to "{new}"',
        shop_title: 'Short name set to "{new}"',
        phase_out: 'Production phase set to "{new}"',
        image: 'Product image stored',
        description: 'Description set to "{new}"',
        code: 'Code set to "{new}"',
        category: 'Category set to "{new}"',
        attributes: {
          attribute: 'Attribute set to "{new}".',
          attribute_value: 'Attribute value set to "{new}".',
          use_in_variable: 'Attribute use in variable set to "{new}".'
        },
        variants: {
          barcode: 'Barcode set to "{new}"',
          price: 'Price set to "{new}"',
          sale_price: 'Sale price set to "{new}"',
          status: 'Product status set to "{new}"',
          variables: {
            barcode: 'Barcode of variant set to "{new}".',
            price: 'Price of variant set to "{new}".',
            sale_price: 'Sale price of variant set to "{new}".',
            status: 'Status of variant set to "{new}".',
            attribute: 'Attribute of variant set to "{new}".',
            attribute_value: 'Attribute value of variant set to "{new}".',
            variables: {
              attribute: 'Attribute of variant set to "{new}".',
              attribute_value: 'Attribute value of variant set to "{new}".'
            }
          }
        }
      },

      service: {
        category: 'Category set to "{new}".',
        title: 'Title set to "{new}".',
        short_title: 'Short title set to "{new}".',
        slug: 'Slug set to "{new}".',
        code: 'Code set to "{new}".',
        price: 'Price set to "{new}".',
        sale_price: 'Sale price set to "{new}".',
        image: 'Image of service updated',
        description: 'Description set to "{new}".',
        max_select: 'Service max select set to "{new}".',
        attributes: {
          attribute: 'Attribute set to "{new}".',
          attribute_value: 'Attribute value set to "{new}".'
        }
      },

      bank: {
        name: 'Name set to "{new}".',
        card_number: 'Card number set to "{new}".',
        account_number: 'Account number set to "{new}".',
        shaba_number: 'Shaba number set to "{new}".'
      },

      cash: {
        name: 'Name set to "{new}".'
      },

      payment_gateway: {
        type: 'Type of payment gateway set to "{new}"',
        title: 'Title set to "{new}"',
        description: 'Description set to "{new}"',
        bank: 'Receiving bank set to "{new}"',
        enabled: 'Status of payment gateway set to "{new}"',
        end_time: 'Working day end time set to "{new}"',
        merchant_code: 'Merchant set to "{new}"',
        terminal_code: 'Terminal set to "{new}"'
      },

      cost: {
        payer: 'Payer set to "{new}".',
        account: 'Account set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        date: 'Date set to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      internal_fund: {
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        type: 'Type set to "{new}".',
        price: 'Price set to "{new}".',
        wage: 'Commission set to "{new}".',
        reference: 'Reference id set to "{new}".',
        date: 'Date set to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      payment: {
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        type: 'Type set to "{new}".',
        date: 'Date set to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      receive: {
        payer: 'Payer set to "{new}".',
        payee: 'Payee set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        status: 'Status set to "{new}".',
        reference: 'Reference id set to "{new}".',
        date: 'Date set to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      tax: {
        payer: 'Payer set to "{new}".',
        from: 'From set to "{new}".',
        to: 'To set to "{new}".',
        description: 'Description set to "{new}".',
        price: 'Price set to "{new}".',
        type: 'Type set to "{new}".',
        date: 'Date set to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      storeroom: {
        name: 'Name set to "{new}".'
      },

      storeroom_receipt: {
        destination: 'Destination set to "{new}".',
        invoice: 'Invoice id set to "{new}".',
        type: 'Type set to "{new}".',
        status: 'Status set to "{new}".',
        date: 'Date set to "{new}".',
        stocks: {
          storeroom: 'Storeroom set to "{new}".',
          product_variant: 'Product variant set to "{new}".',
          quantity: 'Product quantity set to "{new}".',
          unit_price: 'Product unit price set to "{new}".',
          status: 'Status set to "{new}".'
        }
      },

      storeroom_deficit: {
        description: 'Description set to "{new}".',
        status: 'Status set to "{new}".',
        stocks: {
          product_variant: 'Product variant set to "{new}".',
          storeroom: 'Storeroom set to "{new}".',
          quantity: 'Quantity set to "{new}".'
        }
      },

      storeroom_conversion: {
        description: 'Description set to "{new}".',
        status: 'Status set to "{new}".',
        stocks: {
          source_product_variant: 'Product source variant set to "{new}".',
          source_quantity: 'Product source quantity set to "{new}".',
          destination_product_variant: 'Product destination variant set to "{new}".',
          destination_quantity: 'Product destination quantity set to "{new}".',
          storeroom: 'Storeroom set to "{new}".'
        }
      },

      role: {
        name: 'Name set to "{new}".',
        parent: 'Parent set to "{new}".',
        allowed_active_sessions: 'Maximum active sessions set to "{new}".',
        permissions: {
          id: 'Permission id set to "{new}".',
          name: 'Permission name set to "{new}".',
          slug: 'Permission slug set to "{new}".'
        }
      },

      invoice: {
        user: 'User set to "{new}".',
        invoice_number: 'Invoice number set to "{new}".',
        type: 'Type set to "{new}".',
        action_type: 'Action type set to "{new}".',
        status: 'Status set to "{new}".',
        invoice_date: 'Invoice date set to "{new}".',
        details: 'Invoice details set to "{new}".',
        note: 'Note set to "{new}".',
        shipping: 'Shipping price set to "{new}".',
        gift: 'Gift set to "{new}".',
        gift_account: 'Gift account set to "{new}".',
        tax_rate: 'Vat rate set to "{new}".',
        total_tax: 'Total vat rate set to "{new}".',
        total_discount: 'Total discount rate set to "{new}".',
        final_price: 'Final price set to "{new}".',
        attachments: {
          path: 'Attachment stored'
        },
        address: {
          province: 'Province set to "{new}".',
          city: 'City set to "{new}".',
          address: 'Address set to "{new}".',
          post_code: 'Post code set to "{new}".',
          receiver_name: 'Receiver name set to "{new}".',
          receiver_family: 'Receiver family set to "{new}".',
          receiver_call_number: 'Receiver phone number set to "{new}".'
        },
        lines: {
          product_variant: 'Product variant set to "{new}".',
          storeroom: 'Product storeroom set to "{new}".',
          unit_price: 'product unit price set to "{new}".',
          quantity: 'Product quantity set to "{new}".',
          discount: 'Product discount set to "{new}".',
          tax: 'Product vat set to "{new}".'
        },
        services: {
          service: 'Service discount set to "{new}".',
          quantity: 'Service quantity set to "{new}".',
          required: 'Service required set to "{new}".',
          unit_price: 'Service unit price set to "{new}".',
          discount: 'Service discount set to "{new}".',
          cancel_price: 'Service cancel price set to "{new}".',
          vat: 'Service vat set to "{new}".'
        },
        cooperations: {
          cooperator: 'Cooperator set to "{new}".',
          amount: 'Cooperation price set to "{new}".'
        }
      }
    },

    update: {
      switch_active_financial_year: {
        financial_year:  'Active financial year update to "{new}".'
      },

      user: {
        name: 'Name updated from "{old}" to "{new}".',
        family: 'Family updated from "{old}" to "{new}".',
        gender: 'Gender updated from "{old}" to "{new}".',
        avatar: 'User Avatar updated',
        company: 'Company updated from "{old}" to "{new}".',
        phone_number: 'Phone number updated from "{old}" to "{new}".',
        email: 'Email updated from "{old}" to "{new}".',
        max_debt: 'Max debt updated from "{old}" to "{new}".'
      },

      user_address: {
        addresses: {
          address: 'User address updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          province: 'Province updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          title: 'Title updated from "{old}" to "{new}".'
        }
      },

      product: {
        type: 'Product type updated',
        title: 'Ecommerce name updated from "{old}" to "{new}"',
        status: 'Product status updated',
        slug: 'Slug updated from "{old}" to "{new}"',
        shop_title: 'Short name updated from "{old}" to "{new}"',
        phase_out: 'Production phase updated from "{old}" to "{new}"',
        image: 'Product image updated',
        description: 'Description updated from "{old}" to "{new}"',
        code: 'Code updated from "{old}" to "{new}"',
        category: 'Category updated from "{old}" to "{new}"',
        attributes: {
          attribute: 'Attribute updated from "{old}" to "{new}".',
          attribute_value: 'Attribute value updated from "{old}" to "{new}".',
          use_in_variable: 'Attribute use in variable updated from "{old}" to "{new}".'
        },
        variants: {
          barcode: 'Barcode updated from "{old}" to "{new}"',
          price: 'Price updated from "{old}" to "{new}"',
          sale_price: 'Sale price updated from "{old}" to "{new}"',
          status: 'Product status updated',
          variables: {
            barcode: 'Barcode of variant updated from "{old}" to "{new}".',
            price: 'Price of variant updated from "{old}" to "{new}".',
            sale_price: 'Sale price of variant updated from "{old}" to "{new}".',
            status: 'Status of variant updated from "{old}" to "{new}".',
            attribute: 'Attribute of variant updated from "{old}" to "{new}".',
            attribute_value: 'Attribute value of variant updated from "{old}" to "{new}".',
            variables: {
              attribute: 'Attribute of variant updated from "{old}" to "{new}".',
              attribute_value: 'Attribute value of variant updated from "{old}" to "{new}".'
            }
          }
        }
      },

      service: {
        category: 'Category updated from "{old}" to "{new}".',
        title: 'Title updated from "{old}" to "{new}".',
        short_title: 'Short title updated from "{old}" to "{new}".',
        slug: 'Slug updated from "{old}" to "{new}".',
        code: 'Code updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        sale_price: 'Sale price updated from "{old}" to "{new}".',
        image: 'Image of service updated',
        description: 'Description updated from "{old}" to "{new}".',
        max_select: 'Service max select updated from "{old}" to "{new}".',
        attributes: {
          attribute: 'Attribute updated from "{old}" to "{new}".',
          attribute_value: 'Attribute value updated from "{old}" to "{new}".'
        }
      },

      bank: {
        name: 'Name updated from "{old}" to "{new}".',
        card_number: 'Card number updated from "{old}" to "{new}".',
        account_number: 'Account number updated from "{old}" to "{new}".',
        shaba_number: 'Shaba number updated from "{old}" to "{new}".'
      },

      cash: {
        name: 'Name updated from "{old}" to "{new}".'
      },

      payment_gateway: {
        type: 'Type of payment gateway updated',
        title: 'Title updated from "{old}" to "{new}"',
        description: 'Description updated from "{old}" to "{new}"',
        bank: 'Receiving bank updated from "{old}" to "{new}"',
        enabled: 'Status of payment gateway updated',
        end_time: 'Working day end time updated from "{old}" to "{new}"',
        merchant_code: 'Merchant updated from "{old}" to "{new}"',
        terminal_code: 'Terminal updated from "{old}" to "{new}"'
      },

      cost: {
        payer: 'Payer updated from "{old}" to "{new}".',
        account: 'Account updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      internal_fund: {
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        wage: 'Commission updated from "{old}" to "{new}".',
        reference: 'Reference id updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      payment: {
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      receive: {
        payer: 'Payer updated from "{old}" to "{new}".',
        payee: 'Payee updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        reference: 'Reference id updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      tax: {
        payer: 'Payer updated from "{old}" to "{new}".',
        from: 'From updated from "{old}" to "{new}".',
        to: 'To updated from "{old}" to "{new}".',
        description: 'Description updated from "{old}" to "{new}".',
        price: 'Price updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".',
        attachments: {
          path: 'Attachment stored'
        }
      },

      storeroom: {
        name: 'Name updated from "{old}" to "{new}".'
      },

      storeroom_receipt: {
        destination: 'Destination updated from "{old}" to "{new}".',
        invoice_id: 'Invoice id updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        date: 'Date updated from "{old}" to "{new}".',
        stocks: {
          storeroom: 'Storeroom updated from "{old}" to "{new}".',
          productVariant: 'Product variant updated from "{old}" to "{new}".',
          quantity: 'Product quantity updated from "{old}" to "{new}".',
          unit_price: 'Product unit price updated from "{old}" to "{new}".',
          status: 'Status updated from "{old}" to "{new}".'
        }
      },

      storeroom_deficit: {
        description: 'Description updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        stocks: {
          product_variant: 'Product variant updated from "{old}" to "{new}".',
          storeroom: 'Storeroom updated from "{old}" to "{new}".',
          quantity: 'Quantity updated from "{old}" to "{new}".'
        }
      },

      storeroom_conversion: {
        description: 'Description updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        stocks: {
          source_product_variant: 'Product source variant updated from "{old}" to "{new}".',
          source_quantity: 'Product source quantity updated from "{old}" to "{new}".',
          destination_product_variant: 'Product destination variant updated from "{old}" to "{new}".',
          destination_quantity: 'Product destination quantity updated from "{old}" to "{new}".',
          storeroom: 'Storeroom updated from "{old}" to "{new}".'
        }
      },

      role: {
        name: 'Name updated from "{old}" to "{new}".',
        parent: 'Parent updated from "{old}" to "{new}".',
        allowed_active_sessions: 'Maximum active sessions updated from "{old}" to "{new}".',
        permissions: {
          id: 'Permission id updated from "{old}" to "{new}".',
          name: 'Permission name updated from "{old}" to "{new}".',
          slug: 'Permission slug updated from "{old}" to "{new}".'
        }
      },

      invoice: {
        user: 'User updated from "{old}" to "{new}".',
        invoice_number: 'Invoice number updated from "{old}" to "{new}".',
        type: 'Type updated from "{old}" to "{new}".',
        action_type: 'Action type updated from "{old}" to "{new}".',
        status: 'Status updated from "{old}" to "{new}".',
        invoice_date: 'Invoice date updated from "{old}" to "{new}".',
        details: 'Invoice details updated from "{old}" to "{new}".',
        note: 'Note updated from "{old}" to "{new}".',
        shipping: 'Shipping price updated from "{old}" to "{new}".',
        gift: 'Gift updated from "{old}" to "{new}".',
        gift_account: 'Gift account updated from "{old}" to "{new}".',
        tax_rate: 'Vat rate updated from "{old}" to "{new}".',
        total_tax: 'Total vat updated from "{old}" to "{new}".',
        total_discount: 'Total discount updated from "{old}" to "{new}".',
        final_price: 'Final price updated from "{old}" to "{new}".',
        attachments: {
          path: 'Attachment stored'
        },
        address: {
          province: 'Province updated from "{old}" to "{new}".',
          city: 'City updated from "{old}" to "{new}".',
          address: 'Address updated from "{old}" to "{new}".',
          post_code: 'Post code updated from "{old}" to "{new}".',
          receiver_name: 'Receiver name updated from "{old}" to "{new}".',
          receiver_family: 'Receiver family updated from "{old}" to "{new}".',
          receiver_call_number: 'Receiver phone number updated from "{old}" to "{new}".'
        },
        lines: {
          product_variant: 'Product variant updated from "{old}" to "{new}".',
          storeroom: 'Product storeroom updated from "{old}" to "{new}".',
          unit_price: 'product unit price updated from "{old}" to "{new}".',
          quantity: 'Product quantity updated from "{old}" to "{new}".',
          discount: 'Product discount updated from "{old}" to "{new}".',
          tax: 'Product vat updated from "{old}" to "{new}".'
        },
        services: {
          service: 'Service discount updated from "{old}" to "{new}".',
          quantity: 'Service quantity updated from "{old}" to "{new}".',
          required: 'Service required updated from "{old}" to "{new}".',
          unit_price: 'Service unit price updated from "{old}" to "{new}".',
          discount: 'Service discount updated from "{old}" to "{new}".',
          cancel_price: 'Service cancel price updated from "{old}" to "{new}".',
          vat: 'Service vat updated from "{old}" to "{new}".'
        },
        cooperations: {
          cooperator: 'Cooperator updated from "{old}" to "{new}".',
          amount: 'Cooperation price updated from "{old}" to "{new}".'
        }
      }
    }
  }
}
