export default {
  title: 'Service',

  list: {
    title: 'Services',

    table: {
      header: {
        row: 'Row',
        name: 'Name',
        code: 'Code',
        barcode: 'Barcode',
        price: 'Price',
        image: 'Image',
        category: 'Category',
        creator: 'Creator',
        lastModifiedDate: 'Last modified'
      },

      filters: {
        status: {
          all: 'All',
          notConnect: 'No connection',
          draft: 'Draft',
          waiting: 'Waiting',
          published: 'Published',
          personal: 'Personal'
        },

        type: {
          all: 'All',
          simpleProduct: 'Simple service',
          variableProduct: 'Variable service'
        }
      }
    }
  },

  insert: {
    title: 'Insert service',

    labels: {
      importData: 'Upload service opening balance file'
    },

    types: {
      label: 'Select insert type',
      useral: 'Insert to Useral',
      woocommerce: 'Insert to {shopName} and Useral'
    },

    table: {
      header: {
        inventory: 'Inventory',
        barcode: 'Barcode',
        price: 'Price',
        salePrice: 'Sale price',
        delete: 'Delete'
      }
    },

    description: {
      title: 'Note',

      useralDescription: 'The information of this service is registered only in the useral system.',
      woocommerceDescription: 'Service information is recorded directly in your store under review status.',
      notCategorySelected: 'No categories selected',
      noAttributesExistForCategory: 'There are no attributes for this category',
      noAttributeChooseForVariables: 'No attribute selected as variable',
      inventory: 'On this page you can enter the entire product inventory. If the number of inventories is zero, the product will be considered non-existent. ',
      attribute: 'You can select the Attributes you want and add it to the service Attributes list. If the service is a variable type, you can select <b> one </b> of the Attributes as the service variable attribute '
    },

    notifications: {
      type: {
        notSelected: {
          error: 'Add type not selected'
        }
      },
      notConnectToServer: {
        error: 'You are not able to add the service due to not connecting to the site'
      },
      imageUpload: {
        success: 'Photo uploaded successfully'
      },
      imageRemove: {
        success: 'Logo removed successfully'
      },
      insertProduct: {
        success: 'Service information successfully registered',
        error: 'Registration of service information encountered an error'
      },
      woocommerceInsertProduct: {
        success: 'Service information was successfully registered in the store',
        error: 'Registration of service information encountered an error'
      }
    },

    accordionList: {
      brand: {
        title: 'Select the desired brand',

        insert: {
          title: 'Add a new brand',
          brand: 'brand title'
        },

        edit: {
          title: 'Edit Brand',
          category: 'Brand Title'
        },

        delete: {
          title: 'Delete Brand',
          message: 'By deleting this brand, all related information will also be deleted.'
        }
      },

      category: {
        title: 'Select the desired category',

        insert: {
          title: 'Add new category',
          category: 'Category Title'
        },

        edit: {
          title: 'Edit Category',
          category: 'Category Title'
        },

        delete: {
          title: 'Delete Category',
          message: 'Deleting this category will delete all related information.'
        }
      },


      attributes: 'Properties',
      insertAttribute: {
        title: 'Add new feature',
        attribute: 'attribute title',
        values: 'values'
      },
      editAttribute: {
        title: 'Edit Feature',
        attribute: 'attribute title',
        values: 'values'
      },

      buttons: {
        accept: 'Confirm',
        cancel: 'Cancel'
      },

      alert: {
        title: 'Message',
        brandCreated: 'Brand created successfully',
        brandUpdated: 'Brand successfully edited',
        brandDeleted: 'Brand successfully deleted',
        categoryCreated: 'Category created successfully',
        categoryUpdated: 'Category edited successfully',
        categoryDeleted: 'Category deleted successfully',
        errorTitle: 'error',
        error: 'The process went wrong'
      }
    }
  },

  edit: {
    title: 'Edit service',
    dynamicTitle: 'Edit {name}',

    table: {
      header: {
        inventory: 'Inventory',
        barcode: 'Barcode',
        price: 'Price',
        salePrice: 'Sale price',
        delete: 'Delete'
      }
    },

    description: {
      title: 'Description',

      variableDescription: 'You can edit information about each variable in your store through this page.',
      useralDescription: 'The information of this service is registered only in the useral system.',
      woocommerceDescription: 'Service information is recorded directly in your store under review status.',
      notCategorySelected: 'No categories selected',
      noAttributesExist: 'There are no attributes for this category',
      noAttributeChooseForVariables: 'No attribute selected as variable',
      inventory: 'On this page you can enter the entire service inventory. If the number of inventories is zero, the service will be considered non-existent. ',
      attribute: 'You can select the Attributes you want and add it to the service Attributes list. If the service is a variable type, you can select <b> one </b> of the Attributes as the service variable attribute '
    },

    notifications: {
      type: {
        notSelected: {
          error: 'Add type not selected'
        }
      },
      notConnectToServer: {
        error: 'You are not able to add the service due to not connecting to the site'
      },
      imageUpload: {
        success: 'Photo uploaded successfully'
      },
      imageRemove: {
        success: 'Logo removed successfully'
      },
      editProduct: {
        success: 'Service information successfully edited',
        error: 'Edit Service information encountered an error'
      },
      woocommerceEditProduct: {
        success: 'Service information successfully edited',
        error: 'Edit Service information encountered an error'
      },
      deleteVariant: {
        success: 'Variable information successfully deleted',
        error: 'Deleting variable information was associated with an error'
      },
      deleteService: {
        success: 'Service information successfully deleted',
        error: 'Deleting service information was accompanied by an error',
        isUsed: 'Due to the existence of the document, this service cannot be deleted'
      }
    },

    confirmations: {
      removeProduct: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this Service?'
      },
      removeWoocommerceService: {
        title: 'Confirmation',
        body: 'Are you sure you want to delete this item? If confirmed, service information will also be removed from your store! '
      },
      changeProductType: {
        title: 'Warning',
        body: 'If you change the service type of the variable to simple, the information of all variables will be deleted'
      },
      deleteVariable: {
        title: 'Confirmation',
        body: 'Are you sure you want to delete this variable? If confirmed, service information will also be removed from your store! '
      }
    }
  },

  service: {
    title: 'Service',

    labels: {
      withoutPrice: 'No Price'
    },

    importantData: {
      price: 'Price',
      salePrice: 'Sale price',
      category: 'Category',
      type: 'type'
    },

    actions: {
      turnover: 'turnover of Services',
      attributes: 'Service Attributes',
      variants: 'Service variables',
      logs: 'Activity logs'
    },


    attributes: {
      title: 'Service Attributes',
      dynamicTitle: 'Attributes {name}',

      table: {
        header: {
          rowNumber: 'row',
          key: 'attribute title',
          value: 'attribute value'
        }
      }
    },

    turnover: {
      title: 'Circulation of Services',
      dynamicTitle: 'Circulation {name}',

      table: {
        header: {
          rowNumber: 'row',
          type: 'Delivery note type',
          date: 'date',
          variant: 'variable',
          destination: 'Account side',
          quantity: 'Number',
          unitPrice: 'unit amount',
          status: 'Status'
        }
      },

      typeMapper: {
        openingBalance: 'Opening balance',
        receive: 'Pull in',
        exit: 'Pull out',
        transfer: 'transfer'
      },

      statusMapper: {
        sent: 'Posted',
        onWay: 'On the way',
        received: 'Reached warehouse',
        waitingToSend: 'waiting to send'
      }
    },

    inventory: {
      title: 'Inventory',
      dynamicTitle: 'Inventory {name}',

      table: {
        header: {
          rowNumber: 'row',
          storeroom: 'store',
          openingInventory: 'First Course',
          received: 'Reached warehouse',
          sent: 'Posted',
          minInventory: 'Minimum Inventory',
          onWay: 'On the way',
          totalInventory: 'total inventory',
          waitingToSend: 'waiting to send',
          saleInventory: 'Inventory for sale'
        }
      }
    },

    variants: {
      title: 'Service variables',
      dynamicTitle: 'variables {name}',

      table: {
        header: {
          barcode: 'Barcode',
          price: 'Price',
          salePrice: 'Sale price'
        }
      }
    },

    logs: {
      title: 'Service logs',
    },


    type: {
      simple: 'simple',
      variant: 'variable',
      service: 'service'
    }
  },

  select: {
    title: 'Services list',

    table: {
      header: {
        select: 'Select',
        rowNumber: 'row',
        name: 'Title',
        code: 'Code',
        barcode: 'Barcode',
        price: 'Price',
        image: 'Photo',
        category: 'Category',
        type: 'type',
        variant: 'variable',
        salePrice: 'Sale price',
        quantity: 'Inventory',
        storeroom: 'warehouse',
        edit: 'edit'
      },

      filters: {
        type: {
          all: 'All',
          simple: 'simple',
          variant: 'Variable'
        }
      }
    }
  },

  trash: {
    title: 'Removed services',

    table: {
      header: {
        rowNumber: 'Row',
        name: 'Title',
        code: 'Code',
        image: 'Image',
        type: 'Type',
        price: 'Price',
        delete: 'Delete',
        restore: 'Restore'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirmation of deletion of service',
        body: 'Are you sure you want to delete {name} permanently?'
      }
    },

    notifications: {
      restore: {
        success: 'Service returned successfully',
        error: 'Delivery returned failed'
      },
      delete: {
        success: 'Service successfully removed',
        error: 'Deleting the service was accompanied by an error'
      }
    }
  },

  openingBalance: {
    productList: 'Services list',

    insert: {
      title: 'Add service opening balance'
    },


    productType: {
      simple: 'simple',
      variant: 'variable',
      service: 'service'
    },

    labels: {
      count: 'count',
      price: 'Price',
      delete: 'Delete opening balance',
      insertProduct: 'Add New Service',
      importData: 'Upload service opening balance file'
    },

    table: {
      header: {
        rowNumber: 'Row',
        barcode: 'Barcode',
        name: 'Service',
        variant: 'Variable',
        count: 'Count',
        unitPrice: 'Unit amount',
        totalPrice: 'Total amount',
        delete: 'Delete'
      }
    },

    validators: {
      valueExist: 'This value is in the list',
      productExist: '{name} is in list',
      barcode: 'Service with this barcode is not available or exists in the opening balance',
      lock: 'The opening balance is lock'
    },

    notifications: {
      insert: {
        success: 'The warehouse opening balance was successfully registered',
        error: 'The registration of the warehouse opening balance was accompanied by an error!'
      },
      importData: {
        success: 'Service opening balance file successfully uploaded',
        error: 'Upload service opening balance has error'
      },
      edit: {
        success: 'The opening balance of the service in question was successfully updated',
        error: 'The first update of the service period was accompanied by an error'
      },
      delete: {
        success: 'The service opening balance was successfully deleted',
        error: 'Delete desired service opening balance was accompanied by an error'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete Confirmation',
        body: 'Are you sure you want to delete the service opening balance?'
      }
    }
  },

  labels: {
    type: 'Type',
    id: 'Id',
    barcode: 'Barcode',
    url: 'Url',
    productName: 'Title',
    serviceName: 'Title',
    category: 'Category',
    serviceCategory: 'Category',
    price: 'Price',
    productPrice: 'Product Price',
    servicePrice: 'Service Price',
    salePrice: 'Sale price',
    saleStartDate: 'Auction start date',
    saleEndDate: 'Auction end date',
    maxSelect: 'Maximum number of selections',
    description: 'Description',
    inventory: 'Inventory',
    chooseAttribute: 'select attribute',
    chooseCategory: 'select category',
    withoutCategory: 'without category',
    removeService: 'remove Service',
    createVariable: 'create a new variable',
    removeVariable: 'remove variable'
  },

  validators: {
    dateHelp: 'The entry must be entered as {date}.',
    wrongInputValues: 'The information entered is incorrect',
    imageSize: 'The selected file size is too large',
    fileFormat: 'The selected file format is not allowed',
    variantExist: 'There is a variable with this feature',
    variantListTotalCount: 'The total number of possible modes is {max}',
    salePrice: 'The sale price could not be higher than the original price',
    variableSalePrice: 'Variable sale price with barcode {barcode} could not be higher than the original price',
    title: 'There is a service with this name or the name is not entered correctly',
    code: 'There is a service with this code or the code is wrong',
    barcode: 'There is a service with this barcode or the barcode is wrong',
    variableBarcode: 'Row variable barcode {row} is duplicate'
  },

  dataCategory: {
    generalInfo: 'General Information',
    attributes: 'Properties',
    variables: 'variables'
  },

  productType: {
    simple: 'simple',
    variant: 'variable',
    service: 'service'
  },

  pricing: {
    title: 'Service Pricing',

    table: {
      header: {
        rowNumber: 'Row',
        name: 'Title',
        code: 'Code',
        barcode: 'Barcode',
        price: 'Price',
        salePrice: 'Sale price',
        image: 'Image',
        category: 'Category',
        variant: 'Variable',
        creator: 'Creator',
        type: 'Type',
        status: 'Status',
        shops: 'Ecommerce',
        lastModifiedDate: 'Update date'
      },

      filters: {
        type: {
          all: 'All',
          simpleProduct: 'simple services',
          variableProduct: 'Variable services'
        }
      }
    },

    notifications: {
      edit: {
        success: 'Prices of the service were successfully updated',
        error: 'Updated the prices of the service with the error'
      }
    },

    validators: {
      salePrice: 'The sale price cannot be greater than or equal to the original price'
    },

    status: {
      notConnect: 'no connection',
      draft: 'draft',
      waiting: 'pending',
      published: 'published',
      personal: 'private'
    }
  },

  brands: {
    title: 'Brands'
  }
}
